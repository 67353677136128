<template>
    <div :class="{'pr-6 pl-6' : $vuetify.breakpoint.smAndUp}">
        <div class="title font-weight-medium mb-2" v-if="$vuetify.breakpoint.mdAndUp">Warenkorb</div>
        <v-divider></v-divider>
        <div id="cart-container" class="mt-2" :style="{'max-height': $vuetify.breakpoint.mdAndUp ? '54vh' : '46vh'}">
                <ShoppingCartItem
                        v-for="(item,i) in items"
                        :key="item.id"
                        :item="item"
                        :noDivider="i == items.length - 1"
                        class="py-1"
                ></ShoppingCartItem>
        </div>
        <div v-if="items.length == 0" class="body-1 font-weight-medium">Bestellen Sie leckere Speisen</div>
        <v-divider class="mt-2 pb-2" v-if="$vuetify.breakpoint.smAndDown"></v-divider>
        <div class="mt-3">
            <div class="my-1 body-1" v-if="zipCode">Ausgewählte PLZ: <span :class="{'primary--text':zipSelectable}"
                                                                                @click="zipSelectable ? setZipCode(undefined) : undefined"
                                                                                v-if="zipCode"
                                                   :style="{ cursor: zipSelectable ? 'pointer' : 'auto'}"
                                                   >{{zipCode.zipCode}}</span>
            </div>
            <div class="my-1 body-1" v-if="minimumOrder!=0">Mindestbestellwert:
                {{minimumOrder/100 |currency}}</div>
            <div class="my-1 body-1" v-if="zipCode && zipCode.deliveryFee != 0">
              Liefergebühr: {{(zipCode ? zipCode.deliveryFee/100 : 0)|currency}}</div>
            <div class="mt-1 body-1 font-weight-bold" v-if="totalPriceWithoutDeliveryFee > 0">
              Gesamtpreis: {{(totalPrice/100)|currency}}
            </div>
            <v-slide-y-transition>
            <v-banner single-line v-if="totalPriceWithoutDeliveryFee < minimumOrder && totalPriceWithoutDeliveryFee !== 0"
                      color="deep-orange lighten-4"
                      class="mt-2">
                <div class="body-2">
                    Der Mindestbestellwert ist noch nicht erreicht!
                </div>
            </v-banner>
            </v-slide-y-transition>

            <v-btn class="mt-3 rounded-0" block large depressed color="light-green lighten-2"  :disabled="items.length == 0 || totalPriceWithoutDeliveryFee < minimumOrder"
                   v-if="!($route.name=='checkout')" @click="$router.push({name:'checkout'})">            <v-icon color="green darken-4" size="28px" class="pr-2">
              {{ mdiArrowRight }}
            </v-icon> Zur Kasse
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import ShoppingCartItem from "@/components/ShoppingCartItem";
    import {
      mdiArrowRight
    } from '@mdi/js'

    export default {
        components: {ShoppingCartItem},
        name: "ShoppingCart",
        data() {
          return {
            mdiArrowRight
          }
        },
        props:{
            zipSelectable: Boolean
        },
        computed: {
            ...mapGetters({
                zipCode: 'userStore/zipCode',
                items: 'shoppingCartStore/items',
                totalPrice: 'shoppingCartStore/totalPrice',
                totalPriceWithoutDeliveryFee: 'shoppingCartStore/totalPriceWithoutDeliveryFee',
                minimumOrder:'userStore/minimumOrder'
            })
        },
        methods: {
            ...mapActions({
                setZipCode: 'userStore/setZipCode',
            })
        },
    }
</script>

<style scoped lang="sass">
    #cart-container
        overflow-y: auto
</style>
