<template>
        <div class="d-inline d-flex align-center" :class="{'plusMinusSelector': outlined}">
            <v-btn style="touch-action: manipulation;" :class="{'mr-1':small}" :min-width="small ? '20px' : '' " :icon="!outlined" class="px-0" text depressed  @click="decrement" :x-small="small" :large="large"><v-icon color="red" >{{ mdiMinus }}</v-icon></v-btn>
            <span  v-if="!small" id="counter">{{value}}</span>
            <v-btn style="touch-action: manipulation;" :class="{'ml-1':small}" :min-width="small ? '20px' : '' " :icon="!outlined"  class="px-0" text depressed  @click="increment" :x-small="small" :large="large"><v-icon color="green">{{ mdiPlus }}</v-icon></v-btn>
        </div>
</template>

<script>
import { mdiPlus, mdiMinus } from '@mdi/js'

    export default {
        name: "PlusMinusNumberField.vue",
        data(){
          return{
            mdiPlus:mdiPlus,
            mdiMinus:mdiMinus
          }
        },
        props:{
            value: Number,
            max: Number,
            min: Number,
            small: Boolean,
            large: Boolean,
            outlined: Boolean,
        },
        methods: {
            decrement() {
                if(this.min == undefined || this.value > this.min) {
                    this.$emit('input', this.value - 1)
                }
            },
            increment() {
                if(this.max == undefined || this.value < this.max) {
                    this.$emit('input', this.value + 1)
                }
            }
        },
    }
</script>

<style scoped lang="sass">
    #counter
        min-width: 1.5em
        text-align: center
    .plusMinusSelector
        border: 1px solid
        border-radius: 4px
</style>