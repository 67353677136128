<template>
  <div>
    <v-card-text>
    <div v-if="!loading">
      <v-row :key="`question-${question.question}`"
             v-for="(question) in item.questions">
        <v-col cols="12">
          <v-select
              :items="question.choices"
              :label="question.question"
              full-width
              :item-text="returnName"
              item-value="name"
              :clearable="!question.mandatory"
              outlined
              hide-details
              v-model="questionFieldInput[question.question]"
              v-on:input="handleQuestionInput($event,question)"
          ></v-select>
        </v-col>
        <v-col v-if="questionFieldInput[question.question] && question.choices.find(x => x.name ==questionFieldInput[question.question]).extras">
          <extra-input :extras="question.choices.find(x => x.name ==questionFieldInput[question.question]).extras"
                       v-model="questions[question.question].extras"></extra-input>
        </v-col>
      </v-row>
      <v-row v-if="item.extras">
        <v-col cols="12">
          <extra-input :extras="item.extras" v-model="selectedExtras"></extra-input>
        </v-col>
      </v-row>
    </div>
    <v-layout align-center justify-center class="pt-8 pb-8" v-else>
      <v-progress-circular
          indeterminate
          :size="item.queries.includes('extras') ? 75 : 20"
          color="primary"
      ></v-progress-circular>
    </v-layout>
    <v-row>
      <v-col cols="12">
      <v-textarea
          label="Kommentar"
          maxlength="200"
          no-resize
          outlined
          rows="3"
          v-model="commentInput"
      ></v-textarea>
      </v-col>
    </v-row>
    </v-card-text>
    <v-card-actions class="px-4">
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center justify-center">
                  <span style="white-space: nowrap">
                      <plus-minus-number-field
                          v-model="count"
                          :max="999"
                          :min="1"
                          outlined
                          large
                      ></plus-minus-number-field>
                  </span>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center justify-center">
        <v-btn x-large :block="$vuetify.breakpoint.smAndDown" depressed color="light-green lighten-2" :disabled="checkoutDisabled" @click="submitExtras"
               class="px-12">
          <v-icon color="green darken-4" size="28px" class="pr-2">
            {{ selection ? mdiCheck : mdiCart }}
          </v-icon>
          <div class="body-2">{{ combinedPrice / 100 | currency }}</div>
        </v-btn>
      </v-col>
    </v-row>
    </v-card-actions>

  </div>
</template>

<script>
import PlusMinusNumberField from "@/components/PlusMinusNumberField";
import ExtraInput from "@/components/ExtraInput";
import Vue from "vue";
import {mdiCart, mdiCheck} from '@mdi/js'


export default {
  name: "ExtraSelector",
  components: {PlusMinusNumberField, ExtraInput},
  data: () => ({
    mdiCart,
    mdiCheck,
    selectedExtras: [],
    commentInput: "",
    questionFieldInput: {},
    questions: {},
    count: 1,
    id: undefined,
  }),
  props: {
    item: Object,
    selection: Object,
    loading: Boolean
  },
  methods: {
    reset() {
      this.selectedExtras = []
      this.commentInput = ""
      this.questionFieldInput = {}
      this.questions = {}
      this.count = 1
      this.id = undefined
    },
    initializeSelection() {
      let selectionCopy = JSON.parse(JSON.stringify(this.selection))
      this.count = selectionCopy.count
      this.id = selectionCopy.id
      this.commentInput = selectionCopy.comment
      this.selectedExtras = selectionCopy.extras.map(x => x.name)
      Object.entries(selectionCopy.questions).forEach((arr) => {
        this.questionFieldInput[arr[0]] = arr[1].answer.name
        let selectionAnswer = arr[1].answer
        const obj = {
          name: arr[1].question,
          answer: selectionAnswer
        }
        if(selectionAnswer['chosenExtras']){
          obj.extras = selectionAnswer['chosenExtras'].map(x => x.name)
          delete obj.answer['chosenExtras']
        }
        Vue.set(this.questions, arr[0], obj)
      })
    },
    returnName: item => item.price != 0 ? item.name + '  +  ' + item.price / 100 + '€' : item.name,
    handleQuestionInput(name, question) {
      let answeredQuestion = this.item.questions.find(obj => obj.question == question.question)
      let answer = answeredQuestion.choices.find(choice => choice.name == name)
      const obj = {
        name: question.question,
        answer
      }
      if(!this.questions[question.question] || !this.questions[question.question].answer || (this.questions[question.question].answer && this.questions[question.question].answer.name != name)) {
        Vue.set(this.questions, question.question , obj)
      }
    },
    submitExtras() {
      let obj = {
        //the parse/stringify removes all databinding and reference.
        item: JSON.parse(JSON.stringify(this.combinedOrder)),
        count: this.count
      }
      this.$emit("sendUpExtras", obj)
      if (!this.selection) {
        this.reset()
      }
    },
  },
  computed: {
    answeredQuestions() {
      let result = {}, key
      for (key in this.questions) {
        if (Object.prototype.hasOwnProperty.call(this.questions, key) && this.questions[key].answer != undefined) {
          Vue.set(result, key, JSON.parse(JSON.stringify(this.questions[key])))
          if(this.questions[key].extras){
            let chosenExtras = this.questions[key].answer.extras.filter(x => this.questions[key].extras.includes(x.name))
            if(chosenExtras.length > 0){
              Vue.set(result[key].answer, 'chosenExtras', chosenExtras)
            }
            Vue.delete(result[key], 'extras')
          }
        }
      }
      return result;
    },
    combinedOrder() {
      return {
        id: this.id,
        foodId: this.item.id,
        name: this.item.name,
        price: this.item.price,
        extras: this.selectedExtras.map(extraName => this.item.extras.find(extra => extra.name === extraName)),
        questions: this.answeredQuestions,
        comment: this.commentInput
      }
    },
    combinedPrice() {
      let price = Number(this.item.price);
      this.selectedExtras.concat().forEach(name => {
        price += Number(this.item.extras.find(extra => extra.name === name).price)
      });
      Object.values(this.answeredQuestions).forEach(question => {
        if (question.answer && question.answer.price) {
          price += Number(question.answer.price);
        }
        if(question.answer && question.answer['chosenExtras']){
          question.answer['chosenExtras'].forEach(x => {
            price += Number(x.price)
          })
        }
      })
      return price * this.count
    },
    checkoutDisabled() {
      if (this.loading) {
        return true
      } else if (this.item.questions) {
        const unansweredMandatoryQuestions = this.item.questions.filter(x => {
          if (x.mandatory == true && !Object.keys(this.questionFieldInput).includes(x.question)) {
            return true
          }
        })
        if (unansweredMandatoryQuestions.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
  },
  created() {
    if (this.selection) {
      this.initializeSelection()
    } else if(this.item.questions) {
      this.item.questions.forEach(x => {
        if (x.mandatory) {
          this.questionFieldInput[x.question] = x.choices[0].name
          Vue.set(this.questions, x.question, {
            name: x.question,
            answer: x.choices[0]})
        }
      })
    }
  },
  watch: {
    selection: {
      handler() {
        this.initializeSelection()
      },
      deep: true
    },
    item: {
      handler(newItem, oldItem) {
        //This fires if the extra selector gets a new item, but not if the same item just gets it's queries.
        if (newItem.id != oldItem.id) {
          this.reset()
          if (newItem.questions && !this.selection) {
            newItem.questions.forEach(x => {
              if (x.mandatory) {
                this.questionFieldInput[x.question] = x.choices[0].name
                Vue.set(this.questions, x.question, {
                  name: x.question,
                  answer: x.choices[0]})
              }
            })
          }
        }
        else if(!this.selection) {
          //this case should ONLY happen if the queries are done and never else or this breaks i think
          this.item.questions?.forEach(x => {
            if (x.mandatory) {
              this.questionFieldInput[x.question] = x.choices[0].name
              Vue.set(this.questions, x.question, {
                name: x.question,
                answer: x.choices[0]})
            }
          })
        }
      },
      deep: true
    }
  }
}

</script>

<style lang="sass" scoped>

</style>
