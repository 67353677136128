<template>
  <v-card outlined :class="{'px-2': $vuetify.breakpoint.xsOnly}">
    <v-list>
      <v-list-item-group
          multiple
          v-model="selectedExtras"
          class="px-0"
      >
        <span v-for="extra in extraCategories" :key="extra">
            <v-card-title class="overline ml-2 mr-2">{{ extra }}</v-card-title>
            <v-list-item
                v-for="(listItem, i) in extras.filter(x => x.category == extra)"
                :key="`listItem-${i}`"
                :value="listItem.name"
                v-show="(i < 3) || (i >= 3 && expandedExtras.includes(extra)) || (i < 4 && extras.filter(x => x.category == extra).length <= 4) "
                active-class="text--accent-4"
                :class="{'px-1': $vuetify.breakpoint.xsOnly}"
            >
                    <template v-slot:default="{ active }">
                        <v-list-item-action :class="{'mr-1': $vuetify.breakpoint.xsOnly}">
                            <v-checkbox
                                :input-value="active"
                                :true-value="listItem.name"
                                dense
                                color="accent-4"
                            ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-col class="pa-0 ma-0" cols="9">
                                <div class="font-weight-light  body-2">
                                    {{ listItem.name }}
                                </div>
                            </v-col>
                            <v-col v-if="listItem.price != 0" class="pt-0 pr-0 pb-0 ma-0" cols="3"
                                   :class="{'pl-2': $vuetify.breakpoint.xsOnly, 'pl-3': $vuetify.breakpoint.smAndUp}">
                                <span class="font-weight-light body-2">
                                    +
                                </span>
                                <span style="white-space: nowrap;" class="font-weight-light body-2">
                                    {{ listItem.price / 100 |currency }}
                                </span>
                            </v-col>
                        </v-list-item-content>
                    </template>
                </v-list-item>
          <!-- this is a fake list item! -->
            <div class="v-list-item v-list-item--link theme--light"
                 :value="null" v-if="extras.filter(x => x.category == extra).length > 4" @click="expandExtras(extra)">
              <v-list-item-icon>
                <v-icon>{{ expandedExtras.includes(extra) ? mdiChevronUp : mdiChevronDown }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-col class="pa-0 ma-0" cols="9">
                  <div class="font-weight-light  body-2">{{ expandedExtras.includes(extra) ? '' : 'Weitere' }}
                    {{ extras.filter(x => x.category == extra).length - 3}} {{ expandedExtras.includes(extra) ? 'weniger' : '' }} anzeigen...
                  </div>
                </v-col>
              </v-list-item-content>
            </div>
        </span>
      </v-list-item-group>
    </v-list>
  </v-card>

</template>

<script>
import {mdiChevronDown, mdiChevronUp} from "@mdi/js";

export default {
  name: "ExtraInput",
  props:{
    value: Array,
    extras: Array,
  },
  data: () => ({
    mdiChevronDown,
    mdiChevronUp,
    selectedExtras: [],
    expandedExtras: [],
  }),
  methods: {
    reset() {
      this.selectedExtras = []
      this.expandedExtras = [];
    },
    initializeValue() {
      this.selectedExtras = this.value
      this.expandedExtras = []
    },
    expandExtras(categoryName) {
      if (this.expandedExtras.includes(categoryName)) {
        const index = this.expandedExtras.indexOf(categoryName)
        this.expandedExtras.splice(index,1)
      } else {
        this.expandedExtras.push(categoryName)
      }
    },
    submit() {
      this.$emit("input", this.selectedExtras)
  },},
  computed: {
    extraCategories() {
      const arr = this.extras.map(x => {
        if (x.category) {
          return x.category
        } else {
          return 'extras'
        }
      })
      return [...new Set(arr)]
    },
  },
  created() {
    if (this.value) {
      this.initializeValue()
    }
  },
  watch: {
    value: {
      handler() {
        this.selectedExtras = this.value
      },
      deep: true
    },
    selectedExtras: {
      handler() {
        this.submit()
      },
      deep: true
    }
  }
}

</script>

<style scoped>

</style>