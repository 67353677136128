import { render, staticRenderFns } from "./FixedSidebarLayout.vue?vue&type=template&id=07d0edba&scoped=true&"
import script from "./FixedSidebarLayout.vue?vue&type=script&lang=js&"
export * from "./FixedSidebarLayout.vue?vue&type=script&lang=js&"
import style0 from "./FixedSidebarLayout.vue?vue&type=style&index=0&id=07d0edba&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d0edba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VLayout,VProgressCircular})
