<template>
  <div class="mb-2 pr-2">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="560px"
        :fullscreen="$vuetify.breakpoint.mdAndDown">
      <v-card v-if="menuItem" class="pa-2">
        <v-card-title style="word-break: normal">{{ menuItem.name }}</v-card-title>
        <v-card-subtitle v-if="menuItem.description" class="pt-2" style="white-space: pre-wrap;">
          {{ menuItem.description }}
        </v-card-subtitle>
        <extra-selector
            :item="menuItem"
            :loading="dialogLoading"
            :selection="item"
            v-on:sendUpExtras="updateItem"
        ></extra-selector>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col cols="12" md="6">
        <v-row dense>
          <v-col cols="3" v-if="$vuetify.breakpoint.mdAndUp">
            <div class="body-2">
              {{ item.count + 'x ' }}
            </div>
          </v-col>
          <v-col cols="9" md="9">
            <div class="body-2" :class="{'text-subtitle-1 pl-2' : $vuetify.breakpoint.smAndDown}">{{ item.name }}</div>
          </v-col>
          <v-col cols="3" md="0" class="align-self-end text-end d-md-none">
            <div class="text-subtitle-1 font-weight-medium">{{ combinedPrice * item.count / 100 |currency }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <v-layout>
              <plus-minus-number-field
                  v-model="count"
                  :min="0"
                  :max="999"
                  :small="$vuetify.breakpoint.mdAndUp"
                  :large="!$vuetify.breakpoint.mdAndUp"
              ></plus-minus-number-field>
              <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
              <v-btn icon
                     :small="$vuetify.breakpoint.mdAndUp"
                     :class="{'pr-1': $vuetify.breakpoint.smAndDown}"

              >
                <v-icon :size="$vuetify.breakpoint.smAndDown ? '26px' : '18px'" @click="dialog = true">{{ mdiPencil }}</v-icon>
              </v-btn>

              <v-btn icon
                     :small="$vuetify.breakpoint.mdAndUp"
              >
                <v-icon :size="$vuetify.breakpoint.smAndDown ? '26px' : '18px'" @click="deleteItem">{{
                    mdiDelete
                  }}
                </v-icon>
              </v-btn>
            </v-layout>
          </v-col>
          <v-col cols="0" md="4" v-if="$vuetify.breakpoint.mdAndUp" class="align-self-end text-end">
            <div class="body-2">{{ combinedPrice * item.count / 100 |currency }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="Object.keys(item.questions).length > 0 || item.extras.length > 0">
      <v-col>
        <div :class="{'pl-2' : $vuetify.breakpoint.smAndDown}"
             class="body-2 text-left font-italic font-weight-light ml-0 pl-0 pb-0 mb-0 mt-0 pt-2 d-inline">
          <div class="d-inline" v-for="(question,index) in Object.values(item.questions)"
               :key="`answer-${index}`">
            {{ question.answer.name }}
            <span class="d-inline" v-if="question.answer['chosenExtras']">
              (
              <div class="d-inline" v-for="(obj,innerIndex) in question.answer['chosenExtras']" :key="`extras-${question.question}-${obj.name}`">
                {{ obj.name }}
                <div class="d-inline" v-if="innerIndex < question.answer['chosenExtras'].length -1">,</div>
              </div>
              )
            </span>
            <div class="d-inline"
                 v-if="index < Object.keys(item.questions).length -1 || item.extras.length > 0">,
            </div>
          </div>
          <div class="d-inline" v-for="(obj,index) in item.extras" :key="`extras-${obj.name}`">
            {{ obj.name }}
            <div class="d-inline" v-if="index < item.extras.length -1">,</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="item.comment">
      <v-col>
                <span :class="{'pl-2' : $vuetify.breakpoint.smAndDown}"
                      class="text&#45;&#45;secondary font-weight-light text-wrap text-break">
                    {{ item.comment }}
                </span>
      </v-col>
    </v-row>
    <v-divider v-if="$vuetify.breakpoint.smAndDown && !noDivider" class="mt-4"></v-divider>
  </div>
</template>

<script>
import PlusMinusNumberField from "@/components/PlusMinusNumberField";
import ExtraSelector from "./ExtraSelector";
import {mapActions, mapGetters} from "vuex";
import {store} from "../store";
import {mdiPencil, mdiDelete} from '@mdi/js'


export default {
  name: "ShoppingCartItem",
  components: {ExtraSelector, PlusMinusNumberField},
  props: {
    item: Object,
    noDivider: Boolean
  },
  data() {
    return {
      mdiPencil: mdiPencil,
      mdiDelete: mdiDelete,
      dialog: false,
      dialogLoading: false
    }
  },
  methods: {
    ...mapActions({
      ensureQueries: 'menuStore/ensureQueries'
    }),
    incrementItem() {
      this.$store.commit('shoppingCartStore/incrementItem', this.item.id)
    },
    decrementItem() {
      this.$store.commit('shoppingCartStore/decrementItem', this.item.id)
    },
    deleteItem() {
      this.$store.commit('shoppingCartStore/deleteItem', this.item.id)
    },
    updateItem(payload) {
      this.$store.commit('shoppingCartStore/updateItem', payload)
      this.dialog = false
    }
  },
  computed: {
    ...mapGetters({
      getItemByID: 'menuStore/getItemByID'
    }),
    menuItem() {
      return this.getItemByID(this.item.foodId)
    },
    combinedPrice() {
      let price = Number(this.item.price);
      this.item.extras.forEach(extra => {
        price += Number(extra.price);
      });
      Object.values(this.item.questions).forEach(question => {
        if (question.answer && question.answer.price) {
          price += Number(question.answer.price);
        }
        if(question.answer && question.answer['chosenExtras']){
           question.answer['chosenExtras'].forEach(x => {
            price += Number(x.price)
          })
        }
      })
      return price
    },
    count: {
      get: function () {
        return this.item.count
      },
      set: function (count) {
        this.$store.commit('shoppingCartStore/setItemCount', {id: this.item.id, count})
      }
    }
  },
  watch: {
    dialog(value) {
      if (value) {
        this.dialogLoading = true
        this.ensureQueries(this.menuItem).then(() => {
          this.dialogLoading = false
        })
      }
    }
  },

  created() {
    if (!this.menuLoaded) {
      store.dispatch('menuStore/fetchFoodCategories');
    }
  },
}
</script>

<style scoped lang="sass">

</style>
