<template>
    <div>
        <div v-if="!loading" id="layout-wrapper" :class="{big: $vuetify.breakpoint.mdAndUp}">
            <div id="content-wrapper">
                <div id="content-browser">
                        <slot name="content" :class="{ 'mr-4': $vuetify.breakpoint.mdAndUp }">
                        </slot>
                </div>
            </div>
            <div id="sidebar" v-if="$vuetify.breakpoint.mdAndUp">
                <div id="sidebar-content" :style="{ top: sideBarContentHeight +'px'}">
                    <slot name="sidebar">
                    </slot>
                </div>
            </div>
        </div>
        <v-layout align-center justify-center class="pt-12 pb-9" v-else>
            <v-progress-circular
                    indeterminate
                    size="85"
                    color="primary"
            ></v-progress-circular>
        </v-layout>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "FixedSidebarLayout",
        props: {
            loading: Boolean
        },
        computed:{
            ...mapGetters({
                appBarExtended: 'menuStore/appBarExtended',
            }),
            sideBarContentHeight(){
              if(this.$route.name=='menu'){
                if(this.appBarExtended){
                  return 120
                } else {
                  return 55
                }
              } else {
                return 65
              }

            }
        }
    }
</script>

<style scoped lang="sass">
    $sidebar-width: 395px
    #sidebar-content
        position: fixed
        overflow-y: auto
        width: $sidebar-width

    #content-browser
        width: auto

    #layout-wrapper
        display: grid
        grid-template-columns: 100%
        grid-template-areas: 'food cart'

    #layout-wrapper.big
        grid-template-columns: auto $sidebar-width

    #sidebar
        grid-area: cart
        border-left: 2px solid lightgray

    #content-wrapper
        grid-area: food

</style>